import CryptoJS from 'crypto-js'

const create = (settings) => {
	return generateGameLink(settings)
}

const generateGameLink = (settings) => {
	const now = (new Date()).valueOf()
	const rnd = Math.random() * 10^6
	const salt = now * rnd
	const token = CryptoJS.SHA1(settings.title + salt).toString()

	return token
}

export default { create }