import React, { useState } from 'react'
import { Row, Col, Form, Button, InputGroup } from 'react-bootstrap'
import ClipboardJS from 'clipboard'

import gameService from '../../services/gameService'
import './gamer.scss'

const Gamer = () => {
	new ClipboardJS('#share-button')

	const [gameSettings, setGameSettings] = useState({
		title: '',
		description: '',
		hash: ''
	})

	const createGame = (settings) => {
		const hash = gameService.create(gameSettings)
		setGameSettings({ ...gameSettings, hash })
	}

	const shareLink = () => {
		return (
			<div className="share-wrapper">
				<h3>Shareable link</h3>
				<InputGroup className="share-wrapper">
					<InputGroup.Text id="share-link" className="share-link">https://www.dicefury.com/roller/{gameSettings.hash}</InputGroup.Text>
					<InputGroup.Append>
						<Button id="share-button" data-clipboard-target="#share-link">Copy link</Button>
					</InputGroup.Append>
				</InputGroup>
			</div>
		)
	}

	return (
		<>
			<Row>
				<Col>
					<h2>Create a game</h2>
					<p>Create your game below. We'll generate a shareable link you can send to your group.</p>
				</Col>
			</Row>
			<Row>
				<Col className="form-group-wrapper">
					<Form>
						<Form.Group controlId="formGameName">
							<Form.Label>Game title</Form.Label>
							<Form.Control 
								type="text" 
								placeholder="Our awesome game"
								onChange={(ev) => setGameSettings({ ...gameSettings, title: ev.target.value })}
							></Form.Control>
						</Form.Group>
						<Form.Group controlId="formGameDescription">
							<Form.Label>Game description</Form.Label>
							<Form.Control 
								as="textarea" 
								rows="4" 
								placeholder="There will be both dungeons and dragons." 
								onChange={(ev) => setGameSettings({...gameSettings, description: ev.target.value})}
							/>
						</Form.Group>
						<Button onClick={() => createGame()}>Create game!</Button>
						{gameSettings.hash && shareLink()}
					</Form>
				</Col>
			</Row>
			
		</>
	)
}

export default Gamer
