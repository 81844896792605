import React from 'react'
import { Col, Button } from 'react-bootstrap'

const DiceButton = (props) => {
	const { sides, name, hash, handler } = props

	return (
		<Col sm={2} xs={6} className="centered-column">
			<Button className="btn-roll" onClick={() => handler(sides, name, hash)}>{`D${sides}`}</Button>
		</Col>
	)
}

export default DiceButton