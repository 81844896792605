import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { Switch, Route } from 'react-router-dom'

import Roller from './containers/roller'
import Dashboard from './containers/dashboard'
import Gamer from './containers/gamer'

import './stylesheets/base.scss'

const App = () => {
	return (
		<Container>
			<Row>
				<Col>
					<header>
						<img 
							src={process.env.PUBLIC_URL + '/images/logo-dicefury.png'} 
							className="logo" 
							alt="DiceFury tabletop RPG dice simulator" 
						/>
					</header>
				</Col>
			</Row>
			<Row>
				<Col>
					<Switch>
						{/* <Route path="/gamer">
							<Gamer />
						</Route> */}
						<Route path="/roller/:gameRoomHash" children={<Roller />} />
						{/* <Route path="/dashboard">
							<Dashboard />
						</Route> */}
						<Route path="/">
							<Gamer />
						</Route>
					</Switch>
				</Col>
			</Row>
		</Container>
	);
}

export default App;
