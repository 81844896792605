import React, { useState, useEffect } from 'react'
import { Row, Col, Table, InputGroup, FormControl, Button } from 'react-bootstrap'
import socketIOClient from 'socket.io-client'
import { useParams } from 'react-router-dom'

import DiceButton from '../../components/dicebutton'

const endpoint = 'https://socket.dicefury.com'
//const endpoint = 'http://localhost:4000'
const io = socketIOClient(endpoint) 

const rollDice = (sides, name, hash) => {
	if (name.trim() === '') {
		alert('You must set your character name.')
		return
	}

	const roll = Math.floor(Math.random() * sides) + 1
	const now = new Date()

	io.emit('new dice roll', {
		name,
		roll,
		sides,
		hash,
		time: now.toLocaleString(),
	})
}

const Roller = () => {
	const [rolls, setRolls] = useState([])
	const [name, setName] = useState('')
	const [isNameSet, setIsNameSet] = useState(false)
	const { gameRoomHash } = useParams()

	useEffect(() => {
		io.emit("create", gameRoomHash)

		io.on('update history', data => { 
			console.log(data)
			setRolls(r => [data, ...r]) 
		})
	}, [gameRoomHash])

	return (
		<>
			<Row>
				<Col>
					<InputGroup className="form-group-wrapper">
						<FormControl placeholder="Character Name" onChange={ev => setName(ev.target.value)} disabled={isNameSet} />
						<InputGroup.Append>
							<Button onClick={() => setIsNameSet(true)} hidden={isNameSet}>Set Name</Button>
						</InputGroup.Append>
					</InputGroup>
				</Col>
			</Row>
			<Row className="buttons">
				<DiceButton name={isNameSet ? name : ''} hash={gameRoomHash} sides={4} handler={rollDice} />
				<DiceButton name={isNameSet ? name : ''} hash={gameRoomHash} sides={6} handler={rollDice} />
				<DiceButton name={isNameSet ? name : ''} hash={gameRoomHash} sides={8} handler={rollDice} />
				<DiceButton name={isNameSet ? name : ''} hash={gameRoomHash} sides={10} handler={rollDice} />
				<DiceButton name={isNameSet ? name : ''} hash={gameRoomHash} sides={12} handler={rollDice} />
				<DiceButton name={isNameSet ? name : ''} hash={gameRoomHash} sides={20} handler={rollDice} />
			</Row>
			<Row>
				<Col>
					<Table hover>
						<thead>
							<tr>
								<th>Time</th>
								<th>Name</th>
								<th>Die</th>
								<th>Roll</th>
							</tr>
						</thead>
						<tbody>
							{rolls.map(roll => {
								return (
									<tr key={roll.time + Math.random()}>
										<td>{roll.time}</td>
										<td>{roll.name}</td>
										<td>D{roll.sides}</td>
										<td>{roll.roll}</td>
									</tr>
								)
							})}
						</tbody>
					</Table>
				</Col>
			</Row>
		</>
	);
}

export default Roller;
